<template>
  <div class="dialog-wrapper">
    <img :src="picUrl">
    <div class="okr-create">
      <div>OKR仍未创建哦，点击下方快速创建吧！</div>
      <el-button @click="toOkrAdd"><img src="@/assets/img/work/wor-okr-plus-white.svg" alt="">创建目标</el-button>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

export default {
  setup (props, { emit }) {
    const store = useStore()
    const router = useRouter()
    const status = computed(() => store.state.work.signState)
    const depData = computed(() => store.state.okr.toOkrAddId)

    const picUrl = computed(() => getPic(status.value))

    const pic1 = require('@/assets/img/common/sign1.png')
    const getPic = (value) => {
      return pic1
    }

    const toOkrAdd = () => {
      router.push({ name: 'myokr', params: { id: depData.value.depId, depName: depData.value.name } })
      emit('close')
    }

    return {
      status,
      picUrl,
      toOkrAdd
    }
  }
}
</script>

<style lang="less" scoped>
.dialog-wrapper {
  width: 350px;
  height: 312px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
  .okr-create {
    position: absolute;
    z-index: 2;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 300px;
    text-align: center;
    div {
      text-align: center;
      margin-bottom: 20px;
    }
    .el-button {
      width: 200px;
      height: 40px;
      background: #7972f0;
      border-radius: 20px;
      color: #fff;
      border: none;
      img {
        width: 12px;
        margin-right: 8px;
      }
    }
  }
}
</style>

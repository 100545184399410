<template>
  <Header :isNavContent="true">
    <template #navgation>
      <div class="header-center">
        <SearchInput></SearchInput>
        <!-- el-badge 中 isdot属性添加小红点 -->
        <template v-for="item in catalogue" :key="item.id">
          <span
            class="nav-link"
            v-show="item.isShow"
            @mouseenter="openSelect(item.id)"
            @mouseleave="closeSelect(item.id)">
            <router-link
              :to="{ name: item.path }"
              @click="toPage(item.id)"
              :class="{ active: (route.meta.activeRoute === item.path) || (route.meta.activeRoute === 'blogs' && item.name === '微博') || (route.meta.activeRoute === item.pathName && item.name === '工作台') }"
              ><el-badge :is-dot="Boolean(item.isDot)" class="item">
                {{item.name}}
              </el-badge>
            </router-link>
            <el-icon class="arror-bottom" v-if="item.id === 2"><ArrowDownBold /></el-icon>
            <ul class="work-option" v-if="item.id === 2" v-show="showWorkSelect">
              <li
                @click="toLogPage"
                :class="{'workselected': route.meta.selected === 'work-log'}">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="4" y="4" width="12" height="14" rx="1" stroke="#333" stroke-width="2"/><rect x="6.5" y="9.5" width="7" height="1" rx=".5" stroke="#333"/><rect x="6.5" y="13.5" width="7" height="1" rx=".5" stroke="#333"/><rect x="7.5" y="1.5" width="1" height="5" rx=".5" stroke="#333"/><rect x="11.5" y="1.5" width="1" height="5" rx=".5" stroke="#333"/></svg>
                日志
              </li>
              <li
                @click="toOkrPage(route.meta.selected === 'work-okr' ? 'reload' : '')"
                :class="{'workselected': route.meta.selected === 'work-okr'}">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="3.5" y="17.5" width="13" height="1" rx=".5" transform="rotate(-90 3.5 17.5)" stroke="#333"/><rect x="2.5" y="16.5" width="3" height="1" rx=".5" stroke="#333"/><path d="M4 5a1 1 0 0 1 1-1h8.922a1 1 0 0 1 .914 1.406l-.389.876a3 3 0 0 0 0 2.436l.39.876A1 1 0 0 1 13.921 11H5a1 1 0 0 1-1-1V5z" stroke="#333" stroke-width="2"/></svg>
                OKR
              </li>
            </ul>
          </span>
        </template>
      </div>
    </template>
    <template #navgation_right>
      <HeaderRight></HeaderRight>
    </template>
  </Header>
  <div class="main-content">
    <router-view></router-view>
  </div>
  <Footer></Footer>
  <el-backtop :visibility-height="350" :right="20"
    ><img src="@/assets/img/common/gotoTop.svg"
  /></el-backtop>
  <!-- 未读公告 -->
  <template v-if="noticeVisible">
    <img class="notice-bg-left" src="@/assets/img/notice_left.png" >
    <img class="notice-bg-right" src="@/assets/img/notice_right.png" >
  </template>
  <el-dialog v-model="noticeVisible" :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false" custom-class="notice-dialog editor-content">
    <div id="announceContent">
    <h3>{{announceDetail.title}}</h3>
    <p class="notice-copyright">
      <span>{{announceDetail.author}}</span>
      <span>{{formatDate(announceDetail.publish_at ? announceDetail.publish_at : announceDetail.updated_at)}}</span>
    </p>

      <p class="notice-content" v-html="announceDetail.content"></p>
      <!-- 存放链接 -->
        <ul class="file" v-if="announceDetail.file_urls">
          <FileList :fileList="announceDetail.file_urls" ></FileList>
        </ul>
    </div>

    <template #footer>
      <el-button v-if="announceIndex >= announceList.length-1" type="info" @click="closeNoticeDia" :disabled="nextDisbled">关闭
        {{nextTime === 0 ? "" : '(' + nextTime + ')'}}
      </el-button>
      <el-button v-else type="info" @click="nextNoticeDia" :disabled="nextDisbled">下一条
          {{nextTime === 0 ? "" : '(' + nextTime + ')'}}
      </el-button>
    </template>
  </el-dialog>

  <!-- okr创建提醒 -->
    <el-dialog custom-class="create-okr" v-model="createOkrVisible" center :show-close="false" @close="closeCreateOkrDialog">
      <createOkrDialog @close="closeCreateOkrDialog"></createOkrDialog>
    </el-dialog>

    <!-- 员工祝福 -->
    <UserCareDialog />
</template>

<script>
import Header from '@/components/Header'
import SearchInput from '@/components/SearchInput'
import HeaderRight from '@/components/HeaderRight'
import UserCareDialog from '@/components/UserCareDialog'
import CreateOkrDialog from '@/views/layout/work/okr/component/CreateOkrDialog'
import { computed, reactive, toRefs, watch, onMounted, nextTick, onUnmounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
// import SocketService from '@/utils/websocket.js'
import mySocket from '@/mixin/socket.js'
import { getAnnouncementList, getAnnouncementInfo } from '@/apis/announcement.js'
import { setJournalRead } from '@/apis/journal.js'
import waterMaskFn from '@/utils/waterMask.js'
import { formatDate } from '@/utils/filterHtml.js'
import { getCookies } from '@/utils/auth.js'
// import { getOkrExit } from '@/apis/okr.js'
import { dialogMsg } from '@/utils/dialogMsg.js'
import { Toast } from 'vant'
import eventBus from '@/utils/eventBus.js'
// import okrGetMenu from '@/utils/okr/okrGetMenu.js'
export default {
  components: {
    Header,
    SearchInput,
    HeaderRight,
    UserCareDialog,
    CreateOkrDialog
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    // 获取用户列表数据
    getCookies('token') && store.dispatch('getUserList')
    // 添加websocket
    const { data, connectSocket } = mySocket()
    connectSocket()
    data.socketServe.registerCallBack('callback1', data.socketServe)

    function toPage (index) {
      if (index === 0) {
        if (!route.path.includes('/blogs/dynamics')) {
          router.push({ name: 'blogs' })
        } else {
          const hash = window.location.search
          !hash ? window.location.reload() : console.log(hash)
        }
        store.commit('editwebSocketType', 0)
      }
    }
    const state = reactive({
      isMenuShow: computed(() =>
        !store.getters['userInfo/isVirtualUser'] && store.state.userInfo.isWhiteList !== -1
      ),
      userClock: computed(() => {
        return { userInfos: store.state.userInfo.userInfos, isMenuShow: state.isMenuShow }
      }),
      catalogue: [
        {
          id: 0,
          path: '',
          name: '微博',
          isShow: true,
          isDot: computed(() => store.state.WebSocket.type)
        },
        {
          id: 1,
          path: 'forum',
          name: '论坛',
          isShow: true,
          isDot: false
        },
        {
          id: 2,
          path: '',
          name: '工作台',
          pathName: 'work',
          isShow: computed(() => state.isMenuShow),
          isDot: false
        },
        {
          id: 3,
          path: 'announcement',
          name: '公告',
          isShow: computed(() => state.isMenuShow),
          isDot: computed(() => store.state.announcement.unReadAnt > 0)
        }
      ],
      h: computed(() => new Date().getSeconds()),
      timmer: false,
      timeState: false,
      nextTime: 8,
      nextDisbled: true, // 按钮是否可点击
      noticeVisible: false, // 弹窗显示
      noticeFlag: computed(() => store.state.work.noticeFlag), // 展示弹窗判断
      announceList: [],
      announceDetail: {},
      announceIndex: 0,
      intervalId: null,
      username: computed(() => store.state.userInfo.userInfos.name),
      showWorkSelect: false,
      createOkrVisible: computed(() => store.state.okr.createOkrVisible)
    })

    onMounted(async () => {
      document.addEventListener('click', (e) => {
        if (e.target.innerText && e.target.innerText.trim() === '@所有人') {
          e.preventDefault()
        }
      })
    })
    onUnmounted(() => {
      document.addEventListener('click', null)
      store.commit('work/setNoticeFlag', false)
    })
    // 监听用户打卡
    watch(() => state.userClock, async (newValue, oldValue) => {
      // 有打卡权限的用户，排除切换账户导致的状态异常情况
      if ((!oldValue && newValue.isMenuShow) || (oldValue && newValue.userInfos.user_id !== oldValue.userInfos.user_id && newValue.isMenuShow)) {
        // 获取打卡详情
        const result = await store.dispatch('work/getClockState', store.state.userInfo.userInfos.user_id)
        // 检测是否需要打卡并签到
        if (result?.sign_type === 1 && store.state.userInfo.userInfos.need_attendance === 0) {
          Toast.clear()
          state.isMenuShow && eventBus.$emit('openCheckClock')
        } else { // 已签到
          getUserCare()
        }
        // 获取考勤异常
        state.isMenuShow && store.dispatch('work/getClockAbnormal', store.state.userInfo.userInfos.user_id)
      } else if (oldValue && newValue.userInfos.user_id !== oldValue.userInfos.user_id && !newValue.isMenuShow) {
        eventBus.$emit('closeClockDialog')
      }
    }, {
      immediate: true
    })

    // 监听打卡成功后
    watch(() => state.noticeFlag, (newValue) => {
      state.announceIndex = 0
      if (newValue) {
        getAnnounceList(state.announceIndex)
        countDown()
      }
    }, {
      immediate: true
    })
    // 倒计时
    function countDown () {
      clearInterval(state.intervalId)
      state.intervalId = setInterval(() => {
        if (state.nextTime === 0) return (state.nextDisbled = false)
        state.nextTime--
      }, 1000)
    }
    // 显示弹窗 若无未读公告 去除未读flag
    async function getAnnounceList (val) {
      // 获取公告列表 去除规章制度
      await getAnnouncementList({ cate_id: 0, type: 2 }).then((res) => { // 获取未读公告
        if (res.code === 0) {
          const filterNotice = res.data.data.filter(i => i.cate.name !== '规章制度')
          state.announceList = filterNotice.slice(0, 3)
          if (state.announceList.length === 0) {
            // store.commit('work/setNoticeFlag', false)
            getUserCare()
          } else {
            state.noticeVisible = true
            getdetatil(val)
          }
        }
      })
    }

    // 获取公告详情
    function getdetatil (index) {
      getAnnouncementInfo({ id: state.announceList[index].id }).then(res => {
        if (res.code === 0) {
          state.announceDetail = res.data
          store.commit('announcement/setunReadAnt', store.state.announcement.unReadAnt - 1)
        }
      })
    }

    // 下一条
    const nextNoticeDia = () => {
      document.querySelector('.el-dialog__body').scrollTop = 0
      state.nextTime = 8
      countDown()
      setJournalRead({ type: 5, id: state.announceList[state.announceIndex].id })
      getdetatil(++state.announceIndex)
      state.nextDisbled = true
    }
    watch(() => state.announceDetail.content, () => {
      nextTick(() => {
        const waterName = store.state.userInfo.userInfos.name
        waterMaskFn.set(waterName, waterName)
      })
    })
    // 关闭
    const closeNoticeDia = () => {
      setJournalRead({ type: 5, id: state.announceList[state.announceIndex].id })
      state.announceIndex = 0
      clearInterval(state.intervalId)
      state.nextTime = 8
      // store.commit('work/setNoticeFlag', false)
      state.nextDisbled = true
      state.noticeVisible = false
      getUserCare()
    }

    const openSelect = (id) => {
      if (id !== 2) return false
      state.showWorkSelect = true
    }
    const closeSelect = (id) => {
      if (id !== 2) return false
      state.showWorkSelect = false
    }

    const toOkrPage = (type) => {
      if (store.state.work.clockState && store.state.work.clockState.in_company === 'Outside') {
        return dialogMsg('error', '不在公司网络环境，无法查看')
      }
      const tempDep = store.state.okr.departmentList[0]
      state.showWorkSelect = false
      if (!tempDep) {
        dialogMsg('warning', '你还未归属到部门中，不能访问我的okr')
        return false
      }
      if (type === 'reload') {
        return route.name === 'myokr' ? router.go(0) : router.push({ name: 'myokr', params: { id: tempDep.dept_id, depName: tempDep.name } })
      }
      router.push({ name: 'myokr', params: { id: tempDep.dept_id, depName: tempDep.name } })
    }

    const getUserCare = () => {
      const type = store.state.work.clockState.sign_type
      const isProd = location.hostname === 'sns.imyfone.cn'
      store.dispatch('getUserCare', {
        isAccept: true,
        isNextOkr: (isProd && type === 2 && state.noticeFlag) || (!isProd && type === 1) // 线上：签到后至签退前， 测试：未签到
      })
      store.commit('work/setNoticeFlag', false)
    }

    const closeCreateOkrDialog = () => {
      store.commit('okr/setCreateOkrVisible', false)
    }
    const toLogPage = () => {
      if (route.meta.selected === 'work-log') return
      router.push({ name: 'work' })
      state.showWorkSelect = false
    }

    return {
      ...toRefs(state),
      route,
      toPage,
      nextNoticeDia,
      closeNoticeDia,
      store,
      formatDate,
      openSelect,
      closeSelect,
      router,
      toOkrPage,
      closeCreateOkrDialog,
      toLogPage
      // showNotice
    }
  }
}
</script>

<style lang="less">
@import "../../assets/less/common.less";
@import "../../assets/less/ueditorContent.less";
.header-center {
  display: flex;
  justify-content: space-between;
  .nav-link {
    padding-right: 48px;
    white-space: nowrap;
  }
  .nav-link:nth-child(4) {
    padding-right: 47px;
    padding-bottom: 8px;
    position: relative;
  }
  a div {
    min-width: max-content;
  }
  .el-badge {
    padding-bottom: 8px;
  }
  .el-badge__content.is-fixed.is-dot {
    top: 18px;
    right: 2px;
  }
  .el-badge__content--primary {
    background: transparent;
  }
  .arror-bottom {
    margin-left: 7px;
    color: #000;;
  }
  a {
    display: inline-block;
    height: 55px;
    line-height: 55px;
    color: #343434;
    font-size: 18px;
    min-width: 36px;
    position: relative;
    &:nth-of-type(3){
      min-width: 55px;
    }
    &.active {
      color: @default-text-color;
      border-bottom: 3px solid @active-text-color;
      font-weight: bold;
    }
  }
}
.notice-dialog {
  border-radius: 6px;
  min-width: 900px;
  margin-top: calc((100vh - 600px) / 2) !important;
  h3 {
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    padding-top: 6px;
  }
  .notice-copyright {
    text-align: center;
    font-size: 13px;
    height: 25px;
    line-height: 25px;
    padding: 8px 0 24px 0;
    color: @second-text-color;
  }
  .notice-copyright span:first-child {
    margin-right: 18px;
  }
  .notice-content {
    color: @default-text-color;
    font-size: 14px;
    line-height: 25px;
    min-height: 290px;
    img {
      max-width: 100% !important;
      object-fit: contain;
    }
  }
}
.notice-dialog .el-dialog__header {
  display: none;
}
.notice-dialog .el-dialog__body {
  overflow: auto;
  height: 560px;
  .scroll-bar();
  #announceContent {
    position: relative;
    overflow: hidden;
    min-height: 450px;
  }
  h3, p {
    position: relative;
  }
  .file-name {
    font-size: 15px !important;
    z-index: 101;
    p {
      margin-bottom: 0 !important;
    }
  }
  .size {
    font-size: 15px !important;
    margin-bottom: 0 !important;
  }
  .file{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
  li{
    cursor: pointer;
    margin-right: 8px;
    margin-bottom: 8px;
    display: flex;
    height: 74px;
    background: #F6F9FA;
    border: 1px solid #F1F1F1;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 20px 16px;
    .file-title{
      font-size: 13px;
      line-height: 17px;
      color: @default-text-color;
      margin-left: 15px;
    }
    .file-kb{
      font-size: 12px;
      line-height: 16px;
      color: #999999;
      margin-left: 15px;
    }
  }
}
}
.notice-dialog .el-dialog__footer{
    position: absolute;
    left: 50%;
    bottom: -93px;
    transform: translateX(-50%);
}
.notice-dialog .el-dialog__footer .el-button {
    width: 313px;
    height: 45px;
    border-radius: 22.5px;
    background: #FF8401;
    border: none;
}
.notice-bg-left,
.notice-bg-right {
  z-index: 9999;
  position: fixed;
  top: 179px;
}
.notice-bg-left {
  left: 0;
}
.notice-bg-right {
  right: 0;
}
.work-option {
  position: absolute;
  bottom: -90px;
  left: -28px;
  width: 128px;
  height: 92px;
  background: @colorWhite;
  border: 1px solid #F1F1F1;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  padding: 4px;
  &:hover {
    cursor: pointer;
  }
  li {
    display: flex;
    align-items: center;
    border-radius: 3px;
    height: 40px;
    line-height: 40px;
    padding-left: 22px;
    font-family: 'Microsoft YaHei';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: @default-text-color;
    &:hover {
      background: #F1F4F4;
      font-weight: 700;
      font-size: 14px;
      color: @active-text-color !important;
      rect,path {
        stroke: @active-text-color;
      }
    }
    &:first-child {
      margin-bottom: 4px;
    }
    svg {
      margin-right: 10px;
    }
  }
  .workselected {
    background: #F1F4F4;
    font-weight: 700;
    font-size: 14px;
    color: @active-text-color !important;
    rect,path {
      stroke: @active-text-color;
    }
  }
}
.create-okr {
  width: 350px !important;
  height: 312px;
  border: 1px solid #F1F1F1;
  border-radius: 6px;
  overflow: hidden;
  margin-top: calc((100vh - 312px) / 2) !important;
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 0;
    width: auto;
  }
}
</style>
